import React from 'react';
import cn from 'classnames';
import { Grid } from 'react-flexbox-grid';

import s from './Alert.module.scss';

const variants = {
  primary: s.primary,
};

const Alert = ({ children, variant = 'primary', className }) => {
  return <Grid className={cn(s.alert, variants[variant], className)}>{children}</Grid>;
};

export default Alert;
