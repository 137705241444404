import React from 'react';
import Button from 'components/common/Button';
import { iframeUrl } from 'config/settings';
import { useI18n } from 'i18n';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import cn from 'classnames';

import s from './RenovationButton.module.scss';

const RenovationButton = ({
  variant = 'secondary',
  url = iframeUrl,
  className,
  hideArrow,
  title = 'white_label.test_it_yourself',
  containerClassName,
}) => {
  const { t } = useI18n();
  return (
    <a href={url} className={containerClassName} target="_blank" rel="noreferrer">
      <Button className={cn(s.btn, className)} variant={variant}>
        {t(title)} {!hideArrow ? <ArrowRightIcon fill="white" /> : null}
      </Button>
    </a>
  );
};

export default RenovationButton;
